$z-layers: (
  // TODO: Add all the z-indexes here
  // https://shippio.atlassian.net/browse/SHIPPIO2-3233

  // Top Bar
  "top-bar": 3,
  "top-bar-lists": 2,

  // Shipment List
  "shipment-list-header": 1,

  // Shipment Details
  "shipment-details-edit-bar": 10,
  "nss-edit-mode-overlay": 2,

  "default": 1,
);
